<template>
  <v-row class="mt--20">
    <v-col cols="12">
      <ul class="brand-style-2">
        <li v-for="(brand, i) in brandImages" :key="i">
          <img :src="brand.src" alt="Logo Images" />
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        brandImages: [
          {
            src: require("../../assets/images/brand/ban01.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/ban02.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/ban03.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/ban04.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/ban06.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/ban07.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/ban08.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/ban09.png"),
            url: "#",
          },
          {
            src: require("../../assets/images/brand/ban10.png"),
            url: "#",
          },
                    {
            src: require("../../assets/images/brand/ban11.png"),
            url: "#",
          },
                    {
            src: require("../../assets/images/brand/ban12.png"),
            url: "#",
          },
                    {
            src: require("../../assets/images/brand/ban13.png"),
            url: "#",
          },
                    {
            src: require("../../assets/images/brand/ban14.png"),
            url: "#",
          },
                    {
            src: require("../../assets/images/brand/ban15.png"),
            url: "#",
          },          
          
        ],
      };
    },
  };
</script>
